import React from 'react';
import get from 'lodash/get';
import BulkAssign from './BulkAssign';
import BulkUnassign from './BulkUnassign';
import { Link, useNavigate, useLocation, useParams, Navigate } from 'react-router-dom';
import QueryComponent from '../../../common/network/QueryComponent';
import { GET_ROOMS } from '../../../../apollo/queries';
import { hasAdminAccess } from '../../../../utils/user';
import { sortItems } from '../../../../utils/common';
import { useStateValue } from '../../../../context/AppContext';
import { useTitle } from '../../../../constants/useTitle';

function Container() {
  const { user } = useStateValue();
  const location = useLocation();
  const navigate = useNavigate();

  function generateBulkComponent(operation, students) {
    if (students.length > 0) {
      if (operation === 'assign') {
        return (
          <QueryComponent query={{ kind: 'GetRooms', specification: GET_ROOMS }}>
            {(data) => {
              let rooms = get(data, 'viewer.site.rooms') || [];

              if (rooms.length) {
                // Sort the rooms.
                rooms = sortItems(
                  rooms,
                  [
                    {
                      name: 'title',
                      order: 'asc',
                    },
                  ],
                  'natural'
                );

                return <BulkAssign rooms={rooms} students={students} />;
              } else {
                return (
                  <div>
                    {hasAdminAccess(user.rid, user.role) ? (
                      <p data-automation='no-available-seats-msg'>
                        You can&rsquo;t make this change because there aren&rsquo;t enough available seats. You can{' '}
                        <Link to='/rooms' id='add-new-room'>
                          add a new room
                        </Link>{' '}
                        or go back.
                      </p>
                    ) : (
                      <p>
                        You can&rsquo;t make this change because there aren&rsquo;t enough available seats. Ask your
                        coordinator for help.
                        <br /> <br />
                        <Link
                          onClick={(e) => {
                            e.preventDefault();
                            navigate(-1);
                          }}
                          to='#0'
                        >
                          Go back to the student details page.
                        </Link>
                      </p>
                    )}
                  </div>
                );
              }
            }}
          </QueryComponent>
        );
      } else if (operation === 'unassign') {
        return <BulkUnassign students={students} />;
      }
    }
    // to test: go to /students/bulkOperations/assign directly
    return <Navigate to='/' replace />;
  }

  const { operation } = useParams();
  const students = get(location, 'state.students', []);
  useTitle('Move Students');

  return (
    <React.Fragment>
      <h1 className='tdtk-h1 py-4' data-automation='heading-h1'>
        Move Students
      </h1>
      <div className='row'>
        <div className='col-md-8 col-lg-6'>{generateBulkComponent(operation, students)}</div>
      </div>
    </React.Fragment>
  );
}

export default Container;
